<template lang="pug">
Loading(:time="60" ref="loading" :autoClose="false")
#fmaf.maincontain
  //- .bg.bg--sky
    video(playsinline, autoplay, muted, loop)
      source(src="/static/sky2Clip.mov", type="video/mp4")
  img.bg.bg--left(src="@/assets/images/fvlbg-topleft.png")
  img.bg.bg--right(src="@/assets/images/bg-rightbottom.png")
  Works(v-if="dataIsReady", :works="filteredWorks", ref="works")
  .side(:class="{ 'side--down': !isSideBarOpen }")
    .sidebar
      .flex-center.sidebar__arrow(
        :class="{ 'sidebar__arrow--active': isSideBarOpen }",
        @click="isSideBarOpen = !isSideBarOpen"
      )
        img(:src="require(`@/assets/images/sidebar-arrow.svg`)")
      SidebarCollapse(
        :children="false",
        :isClose="collapseCloseArray[0]",
        @click="renderCollapse(0, 0)"
      )
        template(v-slot:title) {{ $t('shared.all') }}
      SidebarCollapse(
        v-for="(location, index) in locations",
        :key="index",
        :children="true",
        :isClose="collapseCloseArray[index + 1]",
        @click="renderCollapse(index + 1, location.work_location_id)"
      )
        template(v-slot:title) {{ location[`name_${localeLang}`] }}
        template(v-slot:content)
          .sidebar__item(
            v-for="work in location.works",
            @click="moveToArtWork(work.slug)"
          )
            .sidebar__item_text {{ work[`work_${localeLang}`]['title'] }}
  //- .content.position-relative(v-if="isShowExhibition" ref="scrollRef")
    // parallax effect
    .parallax-ball(:style="{ top: `calc(18% - ${scrollDistance * 0.2}px)`, left: '34%' }")
    .parallax-ball(:style="{ top: `calc(42% - ${scrollDistance * 0.2}px)`, left: '50%' }")
    .parallax-ball(:style="{ top: `calc(58% - ${scrollDistance * 0.2}px)`, left: '40%' }")
    .parallax-ball(:style="{ top: `calc(72% - ${scrollDistance * 0.2}px)`, left: '66%' }")
    .parallax-ball(:style="{ top: `calc(32% - ${scrollDistance * 0.2}px)`, left: '80%' }")

    //- .row.mt-5.mb-5
      .col-lg-4
      .col-sm-12.col-lg-8.d-flex.flex-wrap.mb-3.overflow-hidden
        .exhibition.d-flex.flex-column.align-items-start.justify-content-end(
          v-for="(work,index) in filteredWorks"
          :key="index"
          :style="{backgroundImage:`url(${$getPhotoUrl(work.photo_1)})`}"
          @click="toArtWork(work.slug)"
          data-aos="fade-right"
          data-aos-delay="200"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
        )
          .exhibition__title.px-2(v-if="work.work_zh.title !== work.work_en.title") {{ work.work_zh.title }}
          .exhibition__title.p-2.pt-0 {{ work.work_en.title }}
</template>

<script>
import ArtistModal from "@/component/ArtistModal.vue";
import SidebarCollapse from "@/component/SidebarCollapse.vue";
import Loading from "@/component/Loading";
import Works from "@/component/Works/";
import { mapState } from "vuex";
import { onMounted, ref } from "vue";
// import useScrollDistance from "@/views/use/useScrollDistance";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "fmaf",
  components: {
    ArtistModal,
    SidebarCollapse,
    Works,
    Loading
  },
  data() {
    return {
      collapseCloseArray: [false, true, true, true], // 初始化sidebar選單要不要打開
      isShowExhibition: true,
      isSideBarOpen: false,
      selectedLocationId: 0,
    };
  },
  computed: {
    ...mapState(["workLocations", "worksFmaf", "dataIsReady"]),
    locations(){
      if(this.workLocations) return this.workLocations.filter(l => !l.name_en.includes("FUTURE VISION LAB"))
      return []
    },
    localeLang() {
      return this.$i18n.locale;
    },
    filteredWorks() {
      if (this.worksFmaf)
        return this.worksFmaf.filter(
          (w) => !w.location.name_en.includes("FUTURE VISION LAB")
        );
      return [];
      // if (this.selectedLocationId === 0) {
      //   if (this.worksFmaf)
      //     return this.worksFmaf.filter(
      //       (w) => !w.location.name_en.includes("FUTURE VISION LAB")
      //     );
      //   return [];
      // }
      // return this.worksFmaf.filter(
      //   (w) =>
      //     w.location.work_location_id === this.selectedLocationId &&
      //     !w.location.name_en.includes("FUTURE VISION LAB")
      // );
    },
    locations() {
      if (this.workLocations)
        return this.workLocations.filter(
          (l) => !l.name_en.includes("FUTURE VISION LAB")
        );
      return [];
    },
  },
  watch: {
    filteredWorks(newValue, oldValue) {
      if (oldValue.length !== 0) {
        setTimeout(() => {
          if (this.dataIsReady) this.$refs.works.resetWorks();
        }, 800);
      }
    }
  },
  methods: {
    renderCollapse: function (collapseIndex, locationId) {
      this.selectedLocationId = locationId;

      console.log("this.collapseCloseArray", this.collapseCloseArray);

      this.collapseCloseArray = this.collapseCloseArray.map(function (
        val,
        index
      ) {
        return collapseIndex !== index;
      });
    },
    toArtWork(slug) {
      this.$store.dispatch("updateCurrentWorkType", "fmaf");
      this.$router.push({ path: `/artworks/${slug}` });
    },
    moveToArtWork(slug){
      this.$refs.works.moveToArtWork(slug)
    }
  },
  setup() {
    const scrollRef = ref(null);
    // const scrollDistance = useScrollDistance(scrollRef);

    onMounted(() => {
      AOS.init();
    });

    return {
      scrollRef,
      // scrollDistance,
    };
  }
};
</script>

<style lang="sass" scoped>
*
  transition: 0.3s ease-out

.row
  margin: 0

#fmaf
  padding: 110px
  width: 100%
  height: 100%
  // margin-bottom: -223px
  // overflow: hidden
  +rwd-md
    padding: 64px 9px 0 9px

.bg
  position: fixed
  pointer-events: none
  z-index: -1
  transition: 1s ease-in
  overflow: hidden

  &--left
    left: 0
    top: 0
    +rwd-md
      display: none

  &--right
    bottom: -10%
    pointer-events: none
    right: 0
    width: 57%
    +rwd-md
      display: none

  &--sky
    padding-top: 27%
    z-index: -1
    overflow: hidden
    width: 60%
    left: 0
    top: 97px
    +rwd-md
      display: none

    video
      width: 100%
      position: absolute
      top: 0
      left: 0
      object-fit: cover
      display: block
      margin: 0
      padding: 0

.side
  z-index: 999
  width: 22%
  display: inline-block
  // position: static
  position: fixed
  border: solid 1px #000
  max-width: 258px
  +rwd-md
    right: 0
    top: auto
    bottom: -1px
    width: 100%
    position: fixed
    padding: 0 9px
    max-height: 90%
    overflow: scroll
    max-width: none
    &--down
      top: calc(100% - 40px)
      bottom: auto
      transition: 1s

.content
  display: inline-block
  vertical-align: top
  width: 74%
  z-index: 0
  +rwd-md
    margin-left: 0
    width: 100%

.exhibition
  z-index: 1
  width: 48%
  min-height: 200px
  background-repeat: no-repeat
  background-size: auto 180%
  background-position: center
  color: white
  margin-right: 2%
  margin-top: 2%
  position: relative
  overflow: hidden
  +rwd-sm
    background-size: 200% auto

  &:hover
    cursor: pointer

  &:before
    content: ' '
    width: 100%
    height: 100%
    position: absolute
    left: 0
    top: 0
    z-index: -1
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.16) 38.65%, rgba(0, 0, 0, 0) 86.29%)

  & > div
    z-index: 5
    width: 100%
    text-align: left

.sidebar
  padding: 17px 0
  width: 100%
  max-height: 80vh
  overflow: scroll
  background-color: #fff
  +rwd-md
    max-width: none
    width: 100%
    bottom: 0

  &__title
    padding: 18px 27px
    font-size: 14px
    font-weight: 700

    &--active
      background: linear-gradient(91.9deg, #BDBDBD 2.61%, #A2A2A2 49.06%, #E7E7E7 73.1%, #8B8B8B 97.74%)
      margin-left: -8px

  &__item
    padding: 5px 8px 5px 60px
    cursor: pointer
    display: flex
    align-items: center

    &:hover > .sidebar__item_text
      background-color: #000
      color: #fff

    &__text
      display: inline

.sidebar__arrow
  display: none
  padding: 0 0 12px
  cursor: pointer
  +rwd-md
    display: flex

  img
    width: 19px
    transform: rotate(180deg)

  &--active
    img
      transform: rotate(0deg)

.parallax-ball
  position: absolute
  width: 40px
  border-radius: 50%
  height: 40px
  background: radial-gradient(49.86% 49.86% at 50.01% 49.96%, #626262 0%, #000000 100%)
</style>
